<template>
  <b-card>
    <b-row>
      <b-col class="d-flex justify-content-between flex-column" md="8" sm="7">
        <!-- Client Avatar -->
        <div class="d-flex justify-content-start">
          <b-avatar rounded size="104px" text="FK" />
          <div class="d-fled flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ client.last_name }} {{ client.first_name }}
                {{ client.middle_name }}
              </h4>
              <span class="card-text">{{ client.email }}</span
              ><br />
              <span class="card-text">{{ client.username }}</span>
            </div>
          </div>
        </div>
        <!-- Client Stats -->
        <div class="d-flex align-items-center mt-2 flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <b-avatar rounded variant="light-primary">
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ client.get_loan_sum || 0 }} BYN</h5>
              <small>Текущий долг</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar rounded variant="light-danger">
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ client.get_fine_sum || 0 }} BYN</h5>
              <small>Просрочка</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar rounded variant="light-info">
              <feather-icon icon="PackageIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ client.application_count || 0 }}</h5>
              <small>Количество заявок</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar rounded variant="light-success">
              <feather-icon icon="PackageIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ client.deals_count || 0 }}</h5>
              <small>Количество сделок</small>
            </div>
          </div>
        </div>
      </b-col>
      <b-col clas="d-flex justify-content-end">
        <div class="text-right">
          <b-button
            :to="{ name: 'client-update', params: { id: client.id } }"
            variant="outline-primary"
          >
            Изменить
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'ClientInfoCard',
  props: ['value'],
  computed: {
    client: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
