<template>
  <div class="client-details">
    <b-row class="mt-2">
      <b-col md="12" sm="6">
        <b-card>
          <table class="w-100">
            <tr>
              <th class="pb-50">Фамилия</th>
              <td>
                {{ details.last_name || '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">Имя</th>
              <td>
                {{ details.first_name || '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">Отчество</th>
              <td>
                {{ details.middle_name || '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">Дата рождения</th>
              <td>
                {{ details.profile.birth_date || '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">Личный номер</th>
              <td>
                {{ details.profile.id_number || '-' }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col v-if="details.get_latest_deal">
        <deal-comments :deal-number="details.get_latest_deal" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DealComments from '@/views/deals/components/DealComments'

export default {
  name: 'ClientDetails',
  components: { DealComments },
  props: ['id'],
  data() {
    return {
      details: { profile: {} },
      doc: {},
    }
  },
  created() {
    this.getClient()
  },
  methods: {
    async getClient() {
      const res = await this.$http.get(`admin/users/${this.id}/`)
      this.details = res.data
    },
  },
}
</script>
