<template>
  <div class="client-docs">
    <b-row>
      <b-col md="12">
        <application-client-docs-card :id="id" :client-mode="true" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-card>
          <b-button :to="{ name: 'client-add-doc' }" block variant="primary">
            Добавить
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApplicationClientDocsCard from '@/views/applications/components/ApplicationClientDocsCard'

export default {
  name: 'ClientDocs',
  components: { ApplicationClientDocsCard },
  props: ['id'],
}
</script>
