<template>
  <b-row>
    <b-col>
      <b-table
        :items="deals"
        :fields="fields"
        responsive
        @row-clicked="openDeal"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ClientDeals',
  props: ['id'],
  data() {
    return {
      deals: [],
      fields: [
        {
          key: 'status_display',
          label: 'Статус',
        },
        {
          key: 'document_number',
          label: 'Договор',
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'value',
          label: 'Сумма, BYN',
        },
        {
          key: 'rate',
          label: 'Ставка',
          formatter: (value) => {
            return `${parseFloat(value) * 100}%`
          },
        },
        {
          key: 'date_document_start',
          label: 'Дата договора',
        },
      ],
    }
  },
  created() {
    this.getDeals()
  },
  methods: {
    async getDeals() {
      const res = await this.$http.get(
        `admin/deals/?application__user=${this.id}`
      )
      this.deals = res.data.results
    },
    openDeal(row) {
      this.$router.push({ name: 'deal-view', params: { id: row.id } })
    },
  },
}
</script>
