<template>
  <div class="client-view">
    <client-info-card v-model="client" />
    <b-tabs v-model="tab" class="mt-1" content-class="pt-1" fill pills>
      <b-tab title="Инфо">
        <client-details v-if="tab === 0" :id="id" />
      </b-tab>
      <b-tab title="Документы">
        <client-docs v-if="tab === 1" :id="id" />
      </b-tab>
      <b-tab title="Заявки">
        <client-applications v-if="tab === 2" :id="id" />
      </b-tab>
      <b-tab title="Сделки">
        <client-deals v-if="tab === 3" :id="id" />
      </b-tab>
      <!--      <b-tab title="Взыскание" />-->
    </b-tabs>
  </div>
</template>

<script>
import ClientInfoCard from '@/views/clients/components/ClientInfoCard'
import ClientDetails from '@/views/clients/components/ClientDetails'
import ClientDocs from '@/views/clients/components/ClientDocs'
import ClientApplications from '@/views/clients/components/ClientApplications'
import ClientDeals from '@/views/clients/components/ClientDeals'

export default {
  name: 'ClientView',
  components: {
    ClientDeals,
    ClientApplications,
    ClientDocs,
    ClientDetails,
    ClientInfoCard,
  },
  data() {
    return {
      tab: 0,
      id: this.$route.params.id,
      client: { profile: {} },
    }
  },
  created() {
    this.getClient()
  },
  methods: {
    async getClient() {
      const res = await this.$http.get(`admin/users/${this.id}`)
      this.client = res.data
    },
  },
}
</script>
