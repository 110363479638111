<template>
  <b-row>
    <b-col>
      <b-table
        :fields="fields"
        :items="applications"
        tbody-tr-class="cursor-pointer"
        responsive
        @row-clicked="openApplication"
      >
        <template #cell(car)="data">
          {{ data.item.car_mark }} - {{ data.item.car_model }}
        </template>
        <template #cell(comments_details)="row">
          <b-button class="mr-2" size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? 'Спрятать' : 'Показать' }} комментарии
          </b-button>
        </template>

        <template #row-details="row">
          <b-table-lite :fields="subFields" :items="[row.item]"></b-table-lite>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'ClientApplications',
  props: ['id'],
  data() {
    return {
      applications: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'created',
          label: 'Дата заявки',
          formatter: (value) => {
            return `${DateTime.fromISO(value).toFormat('dd.LL.yyyy HH:mm')}`
          },
        },
        {
          key: 'status_display',
          label: 'Статус',
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'preferable_amount',
          label: 'Сумма',
        },
        {
          key: 'duration_months',
          label: 'Срок, мес.',
        },
        {
          key: 'car',
          label: 'Авто',
        },
        {
          key: 'comments_details',
          label: 'Комментарии',
        },
      ],
      subFields: [
        {
          key: 'comment',
          label: 'Комментарий',
        },
        {
          key: 'decline_comment',
          label: 'Причина отказа',
        },
      ],
    }
  },
  created() {
    this.getApplications()
  },
  methods: {
    async getApplications() {
      const res = await this.$http.get(`admin/applications/?user=${this.id}`)
      this.applications = res.data.results
    },
    openApplication(row) {
      this.$router.push({ name: 'application-view', params: { id: row.id } })
    },
  },
}
</script>
